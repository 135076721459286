export const HEADERS = {
	DEFAULT_HEADER: {
		'Content-Type': 'application/json; charset=UTF-8'
	},
	header: () => ({
		'Content-Type': 'application/json; charset=UTF-8',
		Authorization: `Bearer ${localStorage.getItem('access_token')}`
	}),
	file_header: () => ({
		'Content-Type': 'multipart/form-data',
		Authorization: `Bearer ${localStorage.getItem('access_token')}`
	}),
	scm_header: () => ({
		'Content-Type': 'application/json; charset=UTF-8',
		Authorization: localStorage.getItem('scm_jwt')
	})
};

export const API_URLS = {
	AUTO_RANKING: {
		autoRanking: (id) => ({
			endPoint: `/api/v1/journal/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		})
	},
	FILE: {
		uploadFile: (id, type) => ({
			endPoint: `/api/v1/file/storage/${id}?type=${type}`,
			method: 'POST',
			headers: HEADERS.file_header()
		}),
		uploadMultiFile: (id) => ({
			endPoint: `/api/v1/file/storage/multi/${id}?type=avatar`,
			method: 'POST',
			headers: HEADERS.file_header()
		})
	},
	PROFILE: {
		getProfileFromUserId: (userId) => ({
			endPoint: `/api/v1/profile/userId/${userId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getAllProfiles: () => ({
			endPoint: `/api/v1/profile`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getProfileById: (profileId) => ({
			endPoint: `/api/v1/profile/${profileId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateProfile: (profileId) => ({
			endPoint: `/api/v1/profile/${profileId}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteProfile: (profileId) => ({
			endPoint: `/api/v1/profile/${profileId}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		getTimeResearchByProfileId: (profileId) => ({
			endPoint: `/api/v1/timeResearchInformation/profile/${profileId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getTimeResearchByProfileIdV2: (profileId, year) => ({
			endPoint: `/api/v1/timeResearchInformationV2/profile/v2/${profileId}?year=${year}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		filterTimeResearch: () => ({
			endPoint: '/api/v1/timeResearchInformationV2/filter',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		updateTimeResearchInformation: (timeResearchInformationId) => ({
			endPoint: `/api/v1/timeResearchInformation/${timeResearchInformationId}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getProfileInfoByProfileId: (profileId) => ({
			endPoint: `/api/v1/profile/info/${profileId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		statisticUnaccepted: () => ({
			endPoint: '/api/v1/statistic/unaccepted',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getProfScore: (profileId) => ({
			endPoint: `/api/v1/profile/score/${profileId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getResourceInfo: (page, pageSize) => ({
			endPoint: `/api/v1/profile/get-resource-info?page=${page}&pageSize=${pageSize}`,
			method: 'GET',
			headers: HEADERS.header()
		})
	},
	KPI: {
		updateKpi: (kpiId) => ({
			endPoint: `/api/v1/kpi/${kpiId}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	KPI_SCIENTIFIC_RESEARCH: {
		getKpiScientificResearchByKpiId: (kpiId) => ({
			endPoint: `/api/v1/kpiScientificResearch/kpi/${kpiId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getKpiScientificResearchById: (kpiScientificResearchId) => ({
			endPoint: `/api/v1/kpiScientificResearch/${kpiScientificResearchId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateKpiScientificResearchById: (kpiScientificResearchId) => ({
			endPoint: `/api/v1/kpiScientificResearch/${kpiScientificResearchId}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		createListKpiScientificResearch: () => ({
			endPoint: `/api/v1/kpiScientificResearch/multiple`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		deleteKpiScientificResearchById: (kpiScientificResearchId) => ({
			endPoint: `/api/v1/kpiScientificResearch/${kpiScientificResearchId}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	SCIENTIFIC_COUNCIL: {
		getScientificCouncil: () => ({
			endPoint: `/api/v1/scientificCouncil`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		createScientificCouncil: () => ({
			endPoint: `/api/v1/scientificCouncil`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateScientificCouncil: () => ({
			endPoint: `/api/v1/scientificCouncil`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteScientificCouncil: (scientificCouncilID) => ({
			endPoint: `/api/v1/scientificCouncil/${scientificCouncilID}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	KPI_PATENT: {
		getKpiPatentByKpiId: (kpiId) => ({
			endPoint: `/api/v1/kpiPatent/kpi/${kpiId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getKpiPatentById: (kpiPatentId) => ({
			endPoint: `/api/v1/kpiPatent/${kpiPatentId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateKpiPatentById: (kpiPatentId) => ({
			endPoint: `/api/v1/kpiPatent/${kpiPatentId}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		createListKpiPatent: () => ({
			endPoint: `/api/v1/kpiPatent/multiple`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		deleteKpiPatentResearchById: (kpiPatentId) => ({
			endPoint: `/api/v1/kpiPatent/${kpiPatentId}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	KPI_PRODUCT_CONTRACT: {
		getKpiProductContractByKpiId: (kpiId) => ({
			endPoint: `/api/v1/kpiProductContract/kpi/${kpiId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getKpiProductContractById: (kpiProductContractId) => ({
			endPoint: `/api/v1/kpiProductContract/${kpiProductContractId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateKpiProductContractById: (kpiProductContractId) => ({
			endPoint: `/api/v1/kpiProductContract/${kpiProductContractId}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		createListKpiProductContract: () => ({
			endPoint: `/api/v1/kpiProductContract/multiple`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		deleteKpiProductContractById: (kpiProductContractId) => ({
			endPoint: `/api/v1/kpiProductContract/${kpiProductContractId}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	KPI_SOLUTION: {
		getKpiSolutionByKpiId: (kpiId) => ({
			endPoint: `/api/v1/kpiSolution/kpi/${kpiId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getKpiSolutionById: (kpiSolutionId) => ({
			endPoint: `/api/v1/kpiSolution/${kpiSolutionId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		createKpiSolution: () => ({
			endPoint: `/api/v1/kpiSolution`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateKpiSolutionById: (kpiSolutionId) => ({
			endPoint: `/api/v1/kpiSolution/${kpiSolutionId}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		createListKpiSolution: () => ({
			endPoint: `/api/v1/kpiSolution/multiple`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		deleteKpiSolutionById: (kpiSolutionId) => ({
			endPoint: `/api/v1/kpiSolution/${kpiSolutionId}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	JOURNAL: {
		getAllJournal: () => ({
			endPoint: '/api/v1/journal',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getAllJournalV2: () => ({
			endPoint: '/api/v1/journal/v2',
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateAllJournal: () => ({
			endPoint: '/api/v1/journal/all',
			method: 'POST',
			headers: HEADERS.header()
		}),
		getFilteredJournal: (page, pageSize) => ({
			endPoint: `/api/v1/journal/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getJournalById: (id) => ({
			endPoint: `/api/v1/journal/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		importJournalByYear: (year) => ({
			endPoint: `/api/v1/journal/importJournal?year=${year}`,
			method: 'POST',
			headers: HEADERS.header()
		})
	},
	JOURNAL_DOMESTIC: {
		getAllJournalDomestic: () => ({
			endPoint: '/api/v1/journalDomestic',
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertJournalDomestic: () => ({
			endPoint: '/api/v1/journalDomestic',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateJournalDomestic: (id) => ({
			endPoint: `/api/v1/journalDomestic/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteJournalDomestic: (id) => ({
			endPoint: `/api/v1/journalDomestic/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	SCIENTIFIC_RESEARCH: {
		getAllScientificResearch: () => ({
			endPoint: '/api/v1/scientificResearch',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getFilteredScientificResearch: (page, pageSize) => ({
			endPoint: `/api/v1/scientificResearch/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		insertScientificResearch: () => ({
			endPoint: '/api/v1/scientificResearch',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateScientificResearch: (id) => ({
			endPoint: `/api/v1/scientificResearch/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteScientificResearch: (id) => ({
			endPoint: `/api/v1/scientificResearch/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		getAllScientificResearchById: (id) => ({
			endPoint: `/api/v1/scientificResearch/profileId/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		acceptScientificResearch: (id, accept) => ({
			endPoint: `/api/v1/scientificResearch/accepted/${id}?accepted=${accept}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getAllPurpose: () => ({
			endPoint: '/api/v1/purpose',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getDuplicateScientificResearch: () => ({
			endPoint: '/api/v1/scientificResearch/duplicate',
			method: 'GET',
			headers: HEADERS.header()
		})
	},
	SCIENTIFIC_RESEARCH_OTHER: {
		getAllScientificResearchOther: () => ({
			endPoint: '/api/v1/scientificResearchOther',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getAllScientificResearchOtherByProfileId: (id) => ({
			endPoint: `/api/v1/scientificResearchOther/profileId/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertScientificResearchOther: () => ({
			endPoint: '/api/v1/scientificResearchOther',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateScientificResearchOther: (id) => ({
			endPoint: `/api/v1/scientificResearchOther/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteScientificResearchOther: (id) => ({
			endPoint: `/api/v1/scientificResearchOther/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		acceptScientificResearchOther: (id, accept) => ({
			endPoint: `/api/v1/scientificResearchOther/accepted/${id}?accepted=${accept}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	PATENT: {
		getAllPatent: () => ({
			endPoint: '/api/v1/patent',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getFilteredPatent: (page, pageSize) => ({
			endPoint: `/api/v1/patent/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getPatentById: (id) => ({
			endPoint: `/api/v1/patent/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getAllPatentByProfileId: (id) => ({
			endPoint: `/api/v1/patent/profileId/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertPatent: () => ({
			endPoint: '/api/v1/patent',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updatePatent: (id) => ({
			endPoint: `/api/v1/patent/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deletePatent: (id) => ({
			endPoint: `/api/v1/patent/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		acceptPatent: (id, accept) => ({
			endPoint: `/api/v1/patent/accepted/${id}?accepted=${accept}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	SOLUTION: {
		getAllSolution: () => ({
			endPoint: '/api/v1/solution',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getAllSolutionById: (id) => ({
			endPoint: `/api/v1/solution/profileId/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getFilteredSolution: (page, pageSize) => ({
			endPoint: `/api/v1/solution/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getSolutionById: (id) => ({
			endPoint: `/api/v1/solution/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertSolution: () => ({
			endPoint: '/api/v1/solution',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateSolution: (id) => ({
			endPoint: `/api/v1/solution/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteSolution: (id) => ({
			endPoint: `/api/v1/solution/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		acceptSolution: (id, accept) => ({
			endPoint: `/api/v1/solution/accepted/${id}?accepted=${accept}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	CONTRACT: {
		getAllContract: () => ({
			endPoint: '/api/v1/productContract',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getFilteredContract: (page, pageSize) => ({
			endPoint: `/api/v1/productContract/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getAllContractByProfileId: (id) => ({
			endPoint: `/api/v1/productContract/profileId/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getContractById: (id) => ({
			endPoint: `/api/v1/productContract/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertContract: () => ({
			endPoint: '/api/v1/productContract',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateContract: (id) => ({
			endPoint: `/api/v1/productContract/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteContract: (id) => ({
			endPoint: `/api/v1/productContract/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		acceptContract: (id, accept) => ({
			endPoint: `/api/v1/productContract/accepted/${id}?accepted=${accept}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	PROJECT: {
		getAllProject: () => ({
			endPoint: '/api/v1/researchProject',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getFilteredProject: (page, pageSize) => ({
			endPoint: `/api/v1/researchProject/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getAllProjectByProfileId: (id) => ({
			endPoint: `/api/v1/researchProject/profileId/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getProjectById: (id) => ({
			endPoint: `/api/v1/researchProject/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertProject: () => ({
			endPoint: '/api/v1/researchProject',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateProject: (id) => ({
			endPoint: `/api/v1/researchProject/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteProject: (id) => ({
			endPoint: `/api/v1/researchProject/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		acceptProject: (id, accept) => ({
			endPoint: `/api/v1/researchProject/accepted/${id}?accepted=${accept}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	MONOGRAPH: {
		getAllMonograph: () => ({
			endPoint: '/api/v1/monograph',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getFilteredMonograph: (page, pageSize) => ({
			endPoint: `/api/v1/monograph/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getAllMonographByProfileId: (id) => ({
			endPoint: `/api/v1/monograph/profileId/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertMonograph: () => ({
			endPoint: '/api/v1/monograph',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateMonograph: (id) => ({
			endPoint: `/api/v1/monograph/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteMonograph: (id) => ({
			endPoint: `/api/v1/monograph/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		acceptMonograph: (id, accept) => ({
			endPoint: `/api/v1/monograph/accepted/${id}?accepted=${accept}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	EMPLOYMENT_CONTRACT: {
		getAllEmploymentContract: () => ({
			endPoint: '/api/v1/contract',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getAllEmploymentContractByProfileId: (profileId) => ({
			endPoint: `/api/v1/contract/profileId/${profileId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getEmploymentContractById: (id) => ({
			endPoint: `/api/v1/contract/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		getEmploymentContractInfoById: (id, year) => ({
			endPoint: `/api/v1/contract/info/${id}/${year}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertEmploymentContract: () => ({
			endPoint: '/api/v1/contract',
			method: 'POST',
			headers: HEADERS.header()
		}),
		filterEmploymentContract: () => ({
			endPoint: '/api/v1/contract/filter',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		updateEmploymentContract: (id) => ({
			endPoint: `/api/v1/contract/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteEmploymentContract: (id) => ({
			endPoint: `/api/v1/contract/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		activateEmploymentContract: (id, active) => ({
			endPoint: `/api/v1/contract/active/${id}?active=${String(!!active)}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		updateKpiCompletedContract: (id, completed) => ({
			endPoint: `/api/v1/contract/completed/${id}?completed=${String(!!completed)}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		checkKpi: (contractId) => ({
			endPoint: `/api/v1/contract/kpi/${contractId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateReceiveNotiEmploymentContract: (id, receiveNoti) => ({
			endPoint: `/api/v1/contract/receiveNoti/${id}?receiveNoti=${String(!!receiveNoti)}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		// KPI
		updateKPI: (id) => ({
			endPoint: `/api/v1/kpi/v2/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		updateKPICompleted: (id, completed) => ({
			endPoint: `/api/v1/kpi/completed/${id}?completed=${completed}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		createKPI: () => ({
			endPoint: '/api/v1/kpi',
			method: 'POST',
			headers: HEADERS.header()
		}),
		deleteKPI: (id) => ({
			endPoint: `/api/v1/kpi/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		getKpiRewardInfo: (kpiId, year) => ({
			endPoint: `/api/v1/contract/kpireward/${kpiId}/${year}`,
			method: 'GET',
			headers: HEADERS.header()
		})
	},
	USER: {
		login: () => ({
			endPoint: '/api/v1/login',
			method: 'POST',
			headers: HEADERS.header()
		}),
		logout: () => ({
			endPoint: '/api/v1/logout',
			method: 'POST',
			headers: HEADERS.header()
		}),
		loginLDAP: () => ({
			endPoint: '/api/v1/login/ldap',
			method: 'POST',
			headers: HEADERS.header()
		}),
		loginWithGoogle: () => ({
			endPoint: '/api/v1/login/google',
			method: 'POST',
			headers: HEADERS.header()
		}),
		refreshToken: () => ({
			endPoint: '/api/v1/token/refresh',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updatePassword: () => ({
			endPoint: '/api/v1/user/change',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getAllUsers: () => ({
			endPoint: '/api/v1/user',
			method: 'GET',
			headers: HEADERS.header()
		}),
		createNewUser: () => ({
			endPoint: '/api/v1/user',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateRole: () => ({
			endPoint: '/api/v1/user/role',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteUserById: (userId) => ({
			endPoint: `/api/v1/user/${userId}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	COUNCIL: {
		getAllCouncil: () => ({
			endPoint: '/api/v1/council/all',
			method: 'GET',
			headers: HEADERS.header()
		}),
		filterCouncil: () => ({
			endPoint: '/api/v1/student_council/filter',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		insertCouncil: () => ({
			endPoint: '/api/v1/student_council/create',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateCouncil: () => ({
			endPoint: '/api/v1/student_council/update',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteCouncil: (id) => ({
			endPoint: `/api/v1/student_council/delete/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		insertInternalCouncil: () => ({
			endPoint: '/api/v1/int_council/create',
			method: 'POST',
			headers: HEADERS.header()
		})
	},
	CONFERENCE: {
		getAllConference: () => ({
			endPoint: '/api/v1/conference',
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertConference: () => ({
			endPoint: '/api/v1/conference',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateConference: (id) => ({
			endPoint: `/api/v1/conference/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteConference: (id) => ({
			endPoint: `/api/v1/conference/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		importConferenceByYear: (year) => ({
			endPoint: `/api/v1/conference/importInternationalConference?year=${year}`,
			method: 'POST',
			headers: HEADERS.header()
		})
	},
	DEPARTMENT: {
		getAllDepartments: () => ({
			endPoint: '/api/v1/department/all',
			method: 'GET',
			headers: HEADERS.DEFAULT_HEADER
		}),
		getAllFaculties: () => ({
			endPoint: '/api/v1/department/faculties',
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertDepartment: () => ({
			endPoint: '/api/v1/department',
			method: 'POST',
			headers: HEADERS.header()
		}),
		getDepartmentById: (departmentId) => ({
			endPoint: `/api/v1/department/${departmentId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateDepartment: (departmentId) => ({
			endPoint: `/api/v1/department/${departmentId}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteDepartment: (departmentId) => ({
			endPoint: `/api/v1/department/${departmentId}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		getAllProfiles: () => ({
			endPoint: '/api/v1/profile',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getProfileByDepartmentId: (departmentId) => ({
			endPoint: `/api/v1/profile/department/${departmentId}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		addProfileToDepartment: () => ({
			endPoint: '/api/v1/departmentProfile',
			method: 'POST',
			headers: HEADERS.header()
		}),
		deleteProfileFromDepartment: (departmentProfileId) => ({
			endPoint: `/api/v1/departmentProfile/${departmentProfileId}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		updateDepartmentProfileById: (departmentProfileId) => ({
			endPoint: `/api/v1/departmentProfile/${departmentProfileId}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	TIME_RESEARCH_INFORMATION: {
		getAllTimeResearchInformation: () => ({
			endPoint: '/api/v1/timeResearchInformation',
			method: 'GET',
			headers: HEADERS.header()
		}),
		getTimeResearchInformation: () => ({
			endPoint: '/api/v1/timeResearchInformation/filter?page=1&pageSize=-1',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getTimeResearchStatistic: () => ({
			endPoint: '/api/v1/statistic/timeResearch',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getTimeResearchInformationById: (id) => ({
			endPoint: `/api/v1/timeResearchInformation/${id}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertTimeResearchInformation: () => ({
			endPoint: '/api/v1/timeResearchInformation',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateTimeResearchInformation: (id) => ({
			endPoint: `/api/v1/timeResearchInformation/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteTimeResearchInformation: (id) => ({
			endPoint: `/api/v1/timeResearchInformation/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		calculateTimeResearch: (profileId, year) => ({
			endPoint: `/api/v1/timeResearchInformation/calTimeResearch?profileId=${profileId}&year=${year}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		calculateTimeResearchAllProfile: (year) => ({
			endPoint: `/api/v1/timeResearchInformation/calTimeResearchAllProfile?year=${year}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		createTimeResearchInformationFromSample: () => ({
			endPoint: `/api/v1/timeResearchInformation`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		lockTimeResearch: (id, locked) => ({
			endPoint: `/api/v1/timeResearchInformation/locked/id/${id}?locked=${locked}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		lockAllTimeResearch: (year, locked) => ({
			endPoint: `/api/v1/timeResearchInformation/locked/${year}?locked=${locked}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	REWARD_INFORMATION: {
		getRewardInformation: () => ({
			endPoint: '/api/v1/rewardInformation',
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateRewardInformation: (id) => ({
			endPoint: `/api/v1/rewardInformation/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getRewardInformationV2: () => ({
			endPoint: '/api/v1/rewardInfoV2',
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateRewardInformationV2: (id) => ({
			endPoint: `/api/v1/rewardInfoV2/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		insertRewardInformationV2: () => ({
			endPoint: `/api/v1/rewardInfoV2/`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		deleteRewardInformationV2: (id) => ({
			endPoint: `/api/v1/rewardInfoV2/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	RANK_TABLE: {
		getRankTable: () => ({
			endPoint: '/api/v1/rankTable',
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateRankTable: (id) => ({
			endPoint: `/api/v1/rankTable/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	EARNINGS_TABLE: {
		getEarningsTable: (year) => ({
			endPoint: `/api/v1/earningsTable/year/${year}`,
			method: 'GET',
			headers: HEADERS.header()
		}),
		updateEarningsTable: (id) => ({
			endPoint: `/api/v1/earningsTable/${id}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteEarningsTable: (id) => ({
			endPoint: `/api/v1/earningsTable/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		postEarningsTable: () => ({
			endPoint: `/api/v1/earningsTable/`,
			method: 'POST',
			headers: HEADERS.header()
		}),
		postImportEarningsTable: () => ({
			endPoint: `/api/v1/earningsTable/import`,
			method: 'POST',
			headers: HEADERS.header()
		})
	},
	STATISTIC: {
		getFinishContractStatistic: () => ({
			endPoint: '/api/v1/statistic/almostFinishContract',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getReviewContractStatistic: () => ({
			endPoint: '/api/v1/statistic/reviewContract',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getScientificResearchStatistic: () => ({
			endPoint: '/api/v1/statistic/scientificResearch',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getRewardContractStatistic: (page, pageSize) => ({
			endPoint: `/api/v1/contract/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		handleUpdateKpiCompleted: (year, completed) => ({
			endPoint: `/api/v1/kpi/completed/year/${year}?completed=${completed}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		getContractRewardByYear: (year) => ({
			endPoint: `/api/v1/contract/kpireward/year/${year}`,
			method: 'GET',
			headers: HEADERS.header()
		})
	},
	NOTIFICATION: {
		sendTimerNotification: () => ({
			endPoint: '/api/v1/timerNotifications/timerNotification/',
			method: 'POST',
			headers: HEADERS.header()
		}),
		sendEmail: () => ({
			endPoint: '/api/v1/emails/email',
			method: 'POST',
			headers: HEADERS.header()
		})
	},
	REWARD: {
		getFilteredProfileReward: (page, pageSize) => ({
			endPoint: `/api/v1/rewardProfile/filter?page=${page}&pageSize=${pageSize}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		calProfileReward: () => ({
			endPoint: '/api/v1/profile/reward',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		calAllProfileReward: () => ({
			endPoint: '/api/v1/profile/rewardAllProfile',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteProfileReward: (id) => ({
			endPoint: `/api/v1/rewardProfile/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		acceptProfileReward: (id, accepted) => ({
			endPoint: `/api/v1/rewardProfile/accepted/${id}?accepted=${accepted}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		lockProfileReward: (id, locked) => ({
			endPoint: `/api/v1/rewardProfile/locked/${id}?locked=${locked}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		lockAllProfileReward: (year, locked) => ({
			endPoint: `/api/v1/rewardProfile/locked/year/${year}?locked=${locked}`,
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	YEARLY_DUE_DATE: {
		getAllYearlyDueDate: () => ({
			endPoint: '/api/v1/due/all',
			method: 'GET',
			headers: HEADERS.header()
		}),
		filterYearlyDueDate: () => ({
			endPoint: '/api/v1/due/filter',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		updateYearlyDueDate: () => ({
			endPoint: '/api/v1/due/update',
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	STUDENT_RESEARCH: {
		getAllStudentResearch: () => ({
			endPoint: '/api/v1/stu_research/all',
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertStudentResearch: () => ({
			endPoint: '/api/v1/stu_research/create',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateStudentResearch: () => ({
			endPoint: '/api/v1/stu_research/update',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteStudentResearch: (id) => ({
			endPoint: `/api/v1/stu_research/delete/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		updateStudentResearchStatus: (id, status) => ({
			endPoint: `/api/v1/stu_research/status/${id}?status=${status}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		filterStudentResearch: () => ({
			endPoint: '/api/v1/stu_research/filter',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		filterStudentResearchV2: () => ({
			endPoint: '/api/v1/stu_research/filter/v2',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		insertStudentGuidance: () => ({
			endPoint: '/api/v1/student_guidance/create',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateStudentGuidance: () => ({
			endPoint: '/api/v1/student_guidance/update',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteStudentGuidance: (id) => ({
			endPoint: `/api/v1/student_guidance/delete/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		})
	},
	INTERNAL_RESEARCH: {
		getAllInternalResearch: () => ({
			endPoint: '/api/v1/int_research/all',
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertInternalResearch: () => ({
			endPoint: '/api/v1/int_research/create',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateInternalResearch: () => ({
			endPoint: '/api/v1/int_research/update',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		updateInternalResearchStatus: (id, status) => ({
			endPoint: `/api/v1/int_research/status/${id}?status=${status}`,
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteInternalResearch: (id) => ({
			endPoint: `/api/v1/int_research/delete/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		filterInternalResearch: () => ({
			endPoint: '/api/v1/int_research/filter',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		filterInternalResearchV2: () => ({
			endPoint: '/api/v1/int_research/filter/v2',
			method: 'PUT',
			headers: HEADERS.header()
		})
	},
	INTELLECTUAL_PROPERTY: {
		getAllIntellectualProperty: () => ({
			endPoint: '/api/v1/intellectual-property/',
			method: 'GET',
			headers: HEADERS.header()
		}),
		insertIntellectualProperty: () => ({
			endPoint: '/api/v1/intellectual-property/',
			method: 'POST',
			headers: HEADERS.header()
		}),
		updateIntellectualProperty: () => ({
			endPoint: '/api/v1/intellectual-property/',
			method: 'PUT',
			headers: HEADERS.header()
		}),
		deleteIntellectualProperty: (id) => ({
			endPoint: `/api/v1/intellectual-property/${id}`,
			method: 'DELETE',
			headers: HEADERS.header()
		}),
		getAllFeeTpye: () => ({
			endPoint: '/api/v1/intellectual-property/fee-type',
			method: 'GET',
			headers: HEADERS.header()
		})
	},
	HRM: {
		// Định mức giờ nghiên cứu
		getDmTimeReseach: () => ({
			endPoint: 'api/v1/timeResearchInformationV2/norm/{year}',
			method: 'GET',
			headers: HEADERS.header()
		})
	}
};
